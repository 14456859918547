var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("time", { attrs: { datetime: "2023-09-20" } }, [
      _c("span", { attrs: { id: "day-of-week" } }, [
        _vm._v(_vm._s(_vm.dayOfWeek))
      ]),
      _c("span", { attrs: { id: "month-date" } }, [
        _vm._v(_vm._s(_vm.month) + " " + _vm._s(_vm.dayOfMonth) + " ")
      ]),
      _c("span", { attrs: { id: "year" } }, [_vm._v(_vm._s(_vm.year))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }