var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { attrs: { id: "summary" } },
    [
      _c(
        "CCol",
        { staticClass: "col-12 col-md-4" },
        [
          _c("CWidgetStatsF", {
            staticClass: "summary-widget",
            attrs: {
              color: "secondary",
              padding: false,
              title: "Active Days",
              invert: ""
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function() {
                  return [
                    _c("CIcon", {
                      staticClass: "fg-primary",
                      attrs: { name: "cil-calendar", size: "xl" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _c("div", { staticClass: "widget-value" }, [
                      _vm._v(" " + _vm._s(_vm.dateDiff) + " ")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-medium-emphasis text-uppercase fw-semibold small",
                        attrs: { id: "widget-title" }
                      },
                      [_vm._v(" Total Days ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "CCol",
        { staticClass: "col-12 col-md-4" },
        [
          _c("CWidgetStatsF", {
            staticClass: "summary-widget",
            attrs: {
              color: "secondary",
              padding: false,
              title: "Active Days",
              invert: ""
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function() {
                  return [
                    _c("CIcon", { attrs: { name: "cil-sun", size: "xl" } })
                  ]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "widget-value",
                        class: ["fw-semibold text-info"]
                      },
                      [_vm._v(" " + _vm._s(_vm.activeDates.length) + " ")]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-medium-emphasis text-uppercase fw-semibold small",
                        attrs: { id: "widget-title" }
                      },
                      [_vm._v(" Active Days ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "CCol",
        { staticClass: "col-12 col-md-4" },
        [
          _c("CWidgetStatsF", {
            staticClass: "summary-widget",
            attrs: {
              color: "secondary",
              padding: false,
              title: "Active Days",
              invert: ""
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function() {
                  return [
                    _c("CIcon", {
                      attrs: { name: "cil-location-pin", size: "xl" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "widget-value",
                        class: ["fw-semibold text-info"]
                      },
                      [_vm._v(" " + _vm._s(_vm.jobCounts) + " ")]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-medium-emphasis text-uppercase fw-semibold small",
                        attrs: { id: "widget-title" }
                      },
                      [_vm._v(" Total Jobs ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }