
<template>
  <div>
    <time datetime="2023-09-20">
      <span id="day-of-week">{{dayOfWeek}}</span>
      <span id="month-date">{{month}} {{dayOfMonth}} </span>
      <span id="year">{{year}}</span>
    </time>
  </div>
</template>


<script>
const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
const dayOfWeekNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export default {
  name: "DateWidget",
  props: {
    date: Date,
  },
  computed: {
    dayOfWeek () {
      return dayOfWeekNames[this.date.getDay()];
    },
    month () {
      return monthNames[this.date.getMonth()];
    },
    dayOfMonth () {
      return this.date.getDate();
    },
    year () {
      return this.date.getYear() + 1900;
    }
  }
};
</script>

<style scoped>
time {
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
}

time * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time #day-of-week {
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: var(--info);
  border-bottom: 1px dashed var(--info);
  /* box-shadow: 0 2px 0 #fd9f1b; */
}

time #year {
  position: absolute;
  bottom: 0.3em;
  color: var(--dark);
}

time #month-date {
  font-size: 1.7em;
  letter-spacing: -0.05em;
  padding-top: 1.3em;
  color: #2f2f2f
}
</style>