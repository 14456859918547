<template>
  <div>
    <CRow>
      <CCol lg="12" color="danger" class="">
        <CRow>
          <CCol sm="12">
            <card-layout :accent-color="accentColor">
              <template #header
              >
                <span> <CIcon name="cil-grid" /> Truck Report </span>
                <div class="p-0 m-0 float-left" v-if="branchInfo" hidden>
                      <span  >
                        <CAvatar
                          :initials="branchInfo.metadata.code"
                          :username="branchInfo.name"
                        />
                      </span>
                      <span class="table-overflow-elipses">{{branchInfo.name}}</span>
                    </div>
              </template>
              <CCardBody id="card-body">
                <CRow>
                  <CCol col="6" class="title-container">
                    
                    <ReportTruckViewTitle
                      v-if="truckSummary"
                      :summary="truckSummary"
                    >
                    </ReportTruckViewTitle>

                  </CCol>

                    <CCol v-show="false">
                    <div class="p-0 m-0 float-right" v-if="branchInfo">
                      <span  >
                        <CAvatar
                          :initials="branchInfo.metadata.code"
                          :username="branchInfo.name"
                        />
                      </span>
                      <span class="table-overflow-elipses">{{branchInfo.name}}</span>
                    </div>
                  </CCol>

                  
                </CRow>
                <CRow id="date-picker" hidden>
                  <CCol>
                    <CDatePicker2
                      :start="start"
                      :end="end"
                      @update="updateDateRange"
                      :max-date="new Date()"
                    /> 
                  </CCol>
                </CRow>
                <!--br KH: removed because should use Margins for padding-->
                <ReportTruckWidgetBar
                  v-if="truckSummary"
                  :summary="truckSummary"
                >
                </ReportTruckWidgetBar>
                <CRow id="list-table">
                  <CCol>
                    <ReportTableScopeTruck
                      @changeToDayView="changeToDayView"
                      @changeToPrintableDayView="changeToPrintableDayView"
                      :summaries="daySummaries"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </card-layout>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import CardLayout from "@/layouts/CardLayout.vue";
import ThemeMixin from "@/mixins/ThemeMixin";
import CDatePicker2 from "@/components/base/CDatePicker2";
// import { DatePicker } from "v-calendar";

import ReportTableScopeTruck from "@/components/tables/ReportTableScopeTruck";
import ReportTruckViewTitle from '@/components/reports/ReportTruckViewTitle';
import ReportTruckWidgetBar from '@/components/reports/ReportTruckWidgetBar';

import { TruckSummary } from '@/lib/rule_lib/truck_summary';
import { TruckDaySummary } from '@/lib/rule_lib/truck_day_summary';
import { JobOperation } from "@/lib/rule_lib/job_operation";
import CAvatar from "@/components/base/CAvatar.vue";



// import { MS_PER_DAY } from "@/components/time/constants"; // not sure why this line not working
const MS_PER_DAY = 86400000; 

const DefaultRangeInDays = 14; 

function withinDateRange(time, startTime, endTime) {
  return time > startTime && time < endTime;
}

export default {
  name: "ReportsListTruckView",
  components: {
    ReportTableScopeTruck,
    ReportTruckViewTitle,
    ReportTruckWidgetBar,
    CDatePicker2,
    CardLayout,
    CAvatar
},
  data() {
    return {
      
    };
  },
  mixins: [ThemeMixin],
  computed: {
    filteringDeviceId () {
      return this.$route.query.device;
    },

    branchInfo(){
      return this.$app.myBranch;
    }, 

    device () {
      if (!this.$store.getters.inventory) {
        return null;
      }
      return this.$store.getters.inventory.find(device => {
        return device.device == this.filteringDeviceId 
      })
    },
    
    truckId () {
      if (this.jobOperations.length) {
        return this.jobOperations[0].truckId;
      } else {
        return "";
      }
    },

    start () {
      return Number(this.$route.query.start) || new Date() - MS_PER_DAY * DefaultRangeInDays;
    },

    end () {
      return Number(this.$route.query.end) || new Date() - 1 + 1;
    },

    dateRange () {
      return {
        start: this.start,
        end: this.end,
      }
    },

    jobs() {
      let output = this.$store.getters.reports.map((item) => {
        return { ...item };
      }).filter(job => {
        if (this.filteringDeviceId) {
          return job.device == this.filteringDeviceId;
        }
        return true;
      }).filter(job => {
        return withinDateRange(job.timestamp, this.start, this.end);
      })
       
      return output;
    },

    jobOperations () {
      return this.jobs.map(job => {
        return new JobOperation({
          id: job.id,
          uid: job.uid,
          truckId: job.truck,
          deviceId: job.device,
          startTimestamp: job.start,
          endTimestamp: job.end,
          score: job.score,
        });
      })
    },

    dateBins() {
      return new Set(
        this.jobOperations.map(jobOperation => {
          return jobOperation.activeDate.toDateString();
        })
      )
    },

    jobsInDateBins () {
      let output = {};
      this.dateBins.forEach(dateBin => {
        output[dateBin] = [];
      });
      this.jobOperations.forEach(jobOperation => {
        let dateBin = jobOperation.activeDate.toDateString();
        output[dateBin].push(jobOperation);
      });
      return output;
    },

    truckSummary () {
      return new TruckSummary(this.filteringDeviceId, this.truckId, this.jobOperations, this.start, this.end);
    },

    daySummaries () {
      let output = {};
      Object.keys(this.jobsInDateBins).forEach(dateString => {
        let dayJobs = this.jobsInDateBins[dateString];
        output[dateString] = new TruckDaySummary(this.filteringDeviceId, this.truckId, dayJobs, new Date(dateString));
      })
      return output;
    },

    activeBranchId () {
      return this.$store.getters.selectedBranch? this.$store.getters.selectedBranch.branch_id : null;
    }
  },

  async created() {
    console.log(`reportSuperListView::created truck: ${this.filteringDeviceId} date: ${this.dayTimestamp}`);
    await this.loadReports();
  },

  // h_todo: to test if it is kept alive
  destroyed () {
    console.log(`reportSuperListView::destroyed truck: ${this.filteringDeviceId} date: ${this.dayTimestamp}`);
  },
  methods: {
    async refresh() {
      await this.loadReports();
    },
    async loadReports() {
      try {
        let params = {
          end: Date.now()
        };
        await this.$app.loadReports(params);
      } catch (err) {
        console.log(err);
      }
    },

    // setReportToActive (uid) {
    //   if (this.activeUID == uid) {
    //     this.activeUID = null;
    //   } else {
    //     this.activeUID = uid;
    //   }
    // },

    // This is the flag for special daily view
    changeToDayView(timestamp) {
      console.log('changeToDayView');
      this.$router.push({ 
        path: '/reports/list/day', 
        query: {
          device: this.filteringDeviceId,
          date: timestamp,
        }
      })
    },

    changeToPrintableDayView(timestamp) {
      console.log('changeToPrintableDayView');
      this.$router.push({ 
        path: '/reports/printable-daily', 
        query: {
          id: this.timestampToDateString(timestamp),
          device: this.filteringDeviceId,
          start: timestamp, // h_todo: not sure why we need a start here
        }
      })
    },

    updateDateRange (range) {
      this.$router.push({ 
        path: '/reports/list/truck', 
        query: {
          device: this.filteringDeviceId,
          start: range.start - 1 + 1, // cast to timestamp
          end: range.end - 1 + 1, // cast to timestamp
        }
      })
    },

    // h_todo: unify the query date param to same yyyy-mm-dd format
    timestampToDateString (timestamp) {
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth returns a number between 0 and 11
      const day = date.getDate();

      return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    }
  },

  watch: {
    async activeBranchId (val) {
      await this.loadReports({
        branch_id: val, // h_todo: api allow branch filter
      }); 
    }
  }
};
</script>


<style scoped>
#card-body {
  padding-top: 0px;
}

.title-container{
  min-width: 300px;
}

</style>
