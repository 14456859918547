var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datetime-container mb-1 ml-1" },
    [
      _c("v-date-picker", {
        ref: "datePicker",
        attrs: {
          mode: "date",
          columns: _vm.$screens({ default: 1, sm: 2 }),
          step: 1,
          "max-date": _vm.maxDate,
          "is-range": ""
        },
        on: { input: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var inputValue = ref.inputValue
              var inputEvents = ref.inputEvents
              return [
                _c("div", { attrs: { id: "table-wrapper" } }, [
                  _c(
                    "table",
                    { staticClass: "table table-sm table-borderless" },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(" From: ")
                          ]),
                          _c("td", [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass:
                                    "px-2 py-1 w-32 rounded disabled focus:outline-none focus:border-indigo-300",
                                  attrs: { size: "" },
                                  domProps: { value: inputValue.start }
                                },
                                inputEvents.start
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v(" To: ")
                          ]),
                          _c("td", [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass:
                                    "px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300",
                                  attrs: { size: "" },
                                  domProps: { value: inputValue.end }
                                },
                                inputEvents.end
                              )
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.range_,
          callback: function($$v) {
            _vm.range_ = $$v
          },
          expression: "range_"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }