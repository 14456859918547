<template>
  <div class="datetime-container mb-1 ml-1">
    <v-date-picker
      ref="datePicker"
      v-model="range_"
      mode="date"
      :columns="$screens({ default: 1, sm: 2 })"
      :step="1"
      :max-date="maxDate"
      v-on:input="onChange"
      is-range
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div id="table-wrapper">
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th scope="row"> From: </th>
                <td>
                  <input
                    size=""
                    :value="inputValue.start"
                    v-on="inputEvents.start"

                    class="px-2 py-1 w-32 rounded disabled focus:outline-none focus:border-indigo-300"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row"> To: </th>
                <td>
                  <input
                    size=""
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    class="px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";

export default {
  name: "CDatePicker2",
  props: {
    button: {
      type: Boolean,
      default: false
    },
    start: Number,
    end: Number,
    maxDate: Number
  },
  components: {
    "v-date-picker": DatePicker
  },
  data() {
    return {
      range_: {
        start: null,
        end: null,
      },

      datePickerKey: 0,
    };
  },
  methods: {
    populateFields () {
      this.range_.start = this.start;
      this.range_.end = this.end;
    },
    update () {
      this.datePickerKey += 1;
      console.log(this.$refs.datePicker.updateValue(this.range_));
      this.$refs.datePicker.$forceUpdate();
      this.$forceUpdate();
    },
    onChange () {
      this.$emit("update", this.range_);
    },
  },
  watch: {
    start: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    },
    end: {
      immediate: true,
      handler () {
        this.populateFields();
      }
    }
  }
};
</script>

<style scoped>
#table-wrapper {
  width: 250px;
}
</style>
