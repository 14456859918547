var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CDataTable", {
    attrs: {
      items: _vm.tableItems,
      fields: _vm.fields,
      "items-per-page": 20,
      hover: "",
      pagination: "",
      cleaner: false,
      striped: "",
      "clickable-rows": ""
    },
    scopedSlots: _vm._u([
      {
        key: "id",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("ReportPerDayScopeTruckCard", {
              attrs: { summary: item },
              on: {
                changeToDayView: _vm.changeToDayView,
                changeToPrintableDayView: _vm.changeToPrintableDayView
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }