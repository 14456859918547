

<template>
  <div id="summary">
    <CCard class="h-100">
      <CCardBody class="pt-1 pb-0 mb-0 card-header  ">
        <div id="truck-id"> TRUCK {{ truckId }} </div>
        <div id="range-from-to"> 
          From 
          <span class="date"> {{startDateString}} </span> 
          to 
          <span class="date">{{endDateString}} </span> 
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>


<script>
import { TruckSummary } from '@/lib/rule_lib/truck_summary';
export default {
  name: "ReportTruckViewTitle",
  props: {
    summary: TruckSummary,
  },
  data() {
    return {
      
    };
  },
  computed: {
    truckId () {
      return this.summary.truckId;
    },

    startDateString () {
      return new Date(this.summary.startTimestamp).toDateString();
    },

    endDateString () {
      return new Date(this.summary.endTimestamp).toDateString();
    },
  },
  methods:{
  }
};
</script>

<style scoped>
#summary {
  /*max-width: 450px; KH: removed because better to limit in a parent controller*/ 
  min-width: 250px; /*KH: We may want to enforce a min width, but better to use overflow techniques */
  word-wrap: break-word;
}

#summary #truck-id {
  font-size: 30px;
  font-weight: 700;
}

#summary .number {
  font-size: 25px;
}

#summary .date {
  font-weight: 500;
}





</style>