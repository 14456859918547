<template>
  <CCard class="my-1 pt-1 mx-1 pb-0 mb-0" @click="changeToPrintableDayView">
    <CCardBody class="mt-0 pt-1 m-0 pb-0 mr-2" id="card-body">
      <div id="date-widget-container" class="section">
        <DateWidget
          :date="activeDate"
        />
      </div>
      <div>

      </div>
      <div id="info-container" class="section">
        <CRow>
          <CCol sm="0" md="1">

          </CCol>
          <CCol sm="12" md="10" id="info-col">
            <CWidgetStatsF class="day-summary-widget" color="light" :padding="false" :width_limit="false">
              <template #icon>

                  <CMedia v-if="useMedia"
                class="p-0 m-0"
                :aside-image-props="{
                  src: '/Mapbox_dark_sample.jpg',
                  height: 50,
                  width: 88,
                }"
              >
                </CMedia>
                <CIcon v-else name="cil-location-pin" size="xl" style="color:var(--info)"/>
              </template>
              <template #value>
                <div class="widget-value" :class="[`fw-semibold text-info`]">
                  {{subParJobCounts}} / {{jobCounts}}
                </div>
              </template>
              <template #title>
                <div id="widget-title" class="text-medium-emphasis text-uppercase fw-semibold small">
                  Failed / Total Jobs
                </div>
              </template>
            </CWidgetStatsF>
            <CRow id="time-range" class="time-range-container" >
              <CCol md="12" lg="6">
              <span class="m-0" style="word-wrap:nomral">
                Start Time: {{ startOfDay }}
              </span>
            </CCol>
            <CCol md="12" lg="6">
              <span class="m-2" style="word-wrap:normal">
                End Time: {{ endOfDay }}
              </span>
            </CCol>
            </CRow>
            <CCard style="height: 50px; margin-top: 20px;" hidden>

            </CCard>
            <CButton color="info" @click="changeToPrintableDayView" class="d-none">
              Go to {{ monthSimple }} {{ dayOfMonth }}
            </CButton>
          </CCol>
          <CCol sm="0" md="1">

          </CCol>
        </CRow>
       
      </div>
      <div id="buttons-container" class="section d-none">
        <CButton color="info" @click="changeToPrintableDayView">
          Go to {{ monthSimple }} {{ dayOfMonth }}
        </CButton>
      </div>
      <div>
        <CScoreCard
          class="p-0"
          :score="score"
          title="Safety Score"
          customHeight="height70"
          customWidth="width170"
        />              
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
// import CScoreCard from "@/components/reports/CScoreCard";
import { TruckDaySummary } from "@/lib/rule_lib/truck_day_summary";
import DateWidget from "@/components/widgets/DateWidget"
import CScoreCard from '@/components/reports/CScoreCard';
import CWidgetStatsF from "@/components/widgets/CWidgetStatsF"






const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const monthSimpleNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG","SEP", "OCT", "NOV", "DEC"]
const dayOfWeekNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

export default {
  name: "ReportPerDayScopeTruckCard",
  components: {
    CScoreCard,
    DateWidget,
    CWidgetStatsF,
  },
  props: {
    summary: TruckDaySummary
  },
  data() {
    return {
      useMedia: true, 
    };
  },
  computed: {
    activeDate () {
      return this.summary.activeDate;
    },

    year () {
      return this.activeDate.getYear() + 1900;
    },

    month () {
      return monthNames[this.activeDate.getMonth()];
    },

    monthSimple () {
      return monthSimpleNames[this.activeDate.getMonth()];
    },
    
    dayOfMonth () {
      return this.activeDate.getDate();
    },

    dayOfWeek () {
      return dayOfWeekNames[this.activeDate.getDay()];
    },

    jobCounts () {
      return this.summary.jobCounts;
    },

    subParJobCounts () {
      return this.summary.subParJobCounts;
    },

    violationCounts () {
      return this.summary.violationCounts;
    },

    job_str () {
      return this.jobCounts > 1? "Jobs" : 'Job'; 
    },

    score () {
      return this.summary.score || "Good"; // h_todo
    },

    startOfDay () {
      return this.$moment(this.summary.startOfDay).format("HH:mm:ss A");
    },

    endOfDay () {
      return this.$moment(this.summary.endOfDay).format("HH:mm:ss A")
    }
  },
  methods: {
    changeToPrintableDayView () {
      this.$emit("changeToPrintableDayView", this.summary.activeDate - 1 + 1);
    }
  }
};
</script>

<style scoped>
#card-body {
  display: flex;
}

#day-widget-container {
  min-width: 150px;
}

#info-container {
  flex: 1;
}

#info-col {
  font-size: 18px;
}

#info-container .text{
  font-size: 15px;
  color: gray;
}

#info-container .number {
  font-size: 30px;
}


#buttons-container {
  min-width: 150px;
}

#card-body .section {
  margin: 10px;
}

#buttons-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

#buttons-container button {
  margin: 5px;
}

.widget-value {
  font-size: 20px;
  color: var(--info);
  font-weight: 500;
}

.time-range-container{
  max-width: 350px;
}

#time-range {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--gray);
}

.day-summary-widget{
  max-width: 350px;
}
</style>
