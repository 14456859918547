var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "12", color: "danger" } },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "card-layout",
                        {
                          attrs: { "accent-color": _vm.accentColor },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-grid" }
                                      }),
                                      _vm._v(" Truck Report ")
                                    ],
                                    1
                                  ),
                                  _vm.branchInfo
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "p-0 m-0 float-left",
                                          attrs: { hidden: "" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c("CAvatar", {
                                                attrs: {
                                                  initials:
                                                    _vm.branchInfo.metadata
                                                      .code,
                                                  username: _vm.branchInfo.name
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "table-overflow-elipses"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.branchInfo.name)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "CCardBody",
                            { attrs: { id: "card-body" } },
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "title-container",
                                      attrs: { col: "6" }
                                    },
                                    [
                                      _vm.truckSummary
                                        ? _c("ReportTruckViewTitle", {
                                            attrs: { summary: _vm.truckSummary }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false"
                                        }
                                      ]
                                    },
                                    [
                                      _vm.branchInfo
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "p-0 m-0 float-right"
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c("CAvatar", {
                                                    attrs: {
                                                      initials:
                                                        _vm.branchInfo.metadata
                                                          .code,
                                                      username:
                                                        _vm.branchInfo.name
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "table-overflow-elipses"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.branchInfo.name)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                { attrs: { id: "date-picker", hidden: "" } },
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("CDatePicker2", {
                                        attrs: {
                                          start: _vm.start,
                                          end: _vm.end,
                                          "max-date": new Date()
                                        },
                                        on: { update: _vm.updateDateRange }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.truckSummary
                                ? _c("ReportTruckWidgetBar", {
                                    attrs: { summary: _vm.truckSummary }
                                  })
                                : _vm._e(),
                              _c(
                                "CRow",
                                { attrs: { id: "list-table" } },
                                [
                                  _c(
                                    "CCol",
                                    [
                                      _c("ReportTableScopeTruck", {
                                        attrs: { summaries: _vm.daySummaries },
                                        on: {
                                          changeToDayView: _vm.changeToDayView,
                                          changeToPrintableDayView:
                                            _vm.changeToPrintableDayView
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }