var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    {
      staticClass: "my-1 pt-1 mx-1 pb-0 mb-0",
      on: { click: _vm.changeToPrintableDayView }
    },
    [
      _c(
        "CCardBody",
        { staticClass: "mt-0 pt-1 m-0 pb-0 mr-2", attrs: { id: "card-body" } },
        [
          _c(
            "div",
            { staticClass: "section", attrs: { id: "date-widget-container" } },
            [_c("DateWidget", { attrs: { date: _vm.activeDate } })],
            1
          ),
          _c("div"),
          _c(
            "div",
            { staticClass: "section", attrs: { id: "info-container" } },
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { sm: "0", md: "1" } }),
                  _c(
                    "CCol",
                    { attrs: { sm: "12", md: "10", id: "info-col" } },
                    [
                      _c("CWidgetStatsF", {
                        staticClass: "day-summary-widget",
                        attrs: {
                          color: "light",
                          padding: false,
                          width_limit: false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function() {
                              return [
                                _vm.useMedia
                                  ? _c("CMedia", {
                                      staticClass: "p-0 m-0",
                                      attrs: {
                                        "aside-image-props": {
                                          src: "/Mapbox_dark_sample.jpg",
                                          height: 50,
                                          width: 88
                                        }
                                      }
                                    })
                                  : _c("CIcon", {
                                      staticStyle: { color: "var(--info)" },
                                      attrs: {
                                        name: "cil-location-pin",
                                        size: "xl"
                                      }
                                    })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "value",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "widget-value",
                                    class: ["fw-semibold text-info"]
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.subParJobCounts) +
                                        " / " +
                                        _vm._s(_vm.jobCounts) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-medium-emphasis text-uppercase fw-semibold small",
                                    attrs: { id: "widget-title" }
                                  },
                                  [_vm._v(" Failed / Total Jobs ")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c(
                        "CRow",
                        {
                          staticClass: "time-range-container",
                          attrs: { id: "time-range" }
                        },
                        [
                          _c("CCol", { attrs: { md: "12", lg: "6" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "m-0",
                                staticStyle: { "word-wrap": "nomral" }
                              },
                              [
                                _vm._v(
                                  " Start Time: " + _vm._s(_vm.startOfDay) + " "
                                )
                              ]
                            )
                          ]),
                          _c("CCol", { attrs: { md: "12", lg: "6" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "m-2",
                                staticStyle: { "word-wrap": "normal" }
                              },
                              [
                                _vm._v(
                                  " End Time: " + _vm._s(_vm.endOfDay) + " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c("CCard", {
                        staticStyle: { height: "50px", "margin-top": "20px" },
                        attrs: { hidden: "" }
                      }),
                      _c(
                        "CButton",
                        {
                          staticClass: "d-none",
                          attrs: { color: "info" },
                          on: { click: _vm.changeToPrintableDayView }
                        },
                        [
                          _vm._v(
                            " Go to " +
                              _vm._s(_vm.monthSimple) +
                              " " +
                              _vm._s(_vm.dayOfMonth) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("CCol", { attrs: { sm: "0", md: "1" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "section d-none",
              attrs: { id: "buttons-container" }
            },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "info" },
                  on: { click: _vm.changeToPrintableDayView }
                },
                [
                  _vm._v(
                    " Go to " +
                      _vm._s(_vm.monthSimple) +
                      " " +
                      _vm._s(_vm.dayOfMonth) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("CScoreCard", {
                staticClass: "p-0",
                attrs: {
                  score: _vm.score,
                  title: "Safety Score",
                  customHeight: "height70",
                  customWidth: "width170"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }