<template>
  <CRow id="summary">
    <CCol class="col-12 col-md-4">
      <CWidgetStatsF
        color="secondary"
        :padding="false"
        title="Active Days"
        class="summary-widget"
        invert
      >
        <template #icon>
          <CIcon name="cil-calendar" class="fg-primary" size="xl" />
        </template>
        <template #value>
          <div class="widget-value">
            {{dateDiff}}
          </div>
        </template>
        <template #title>
          <div
            id="widget-title"
            class="text-medium-emphasis text-uppercase fw-semibold small"
          >
            Total Days
          </div>
        </template>
      </CWidgetStatsF>
    </CCol>
    <CCol class="col-12 col-md-4">
      <CWidgetStatsF
        color="secondary"
        :padding="false"
        title="Active Days"
        class="summary-widget"
        invert
      >
        <template #icon>
          <CIcon name="cil-sun" size="xl" />
        </template>
        <template #value>
          <div class="widget-value" :class="[`fw-semibold text-info`]">
            {{activeDates.length}}
          </div>
        </template>
        <template #title>
          <div
            id="widget-title"
            class="text-medium-emphasis text-uppercase fw-semibold small"
          >
            Active Days
          </div>
        </template>
      </CWidgetStatsF>
    </CCol>
    <CCol class="col-12 col-md-4">
      <CWidgetStatsF
        color="secondary"
        :padding="false"
        title="Active Days"
        class="summary-widget"
        invert
      >
        <template #icon>
          <CIcon name="cil-location-pin" size="xl" />
        </template>
        <template #value>
          <div class="widget-value" :class="[`fw-semibold text-info`]">
            {{ jobCounts }}
          </div>
        </template>
        <template #title>
          <div
            id="widget-title"
            class="text-medium-emphasis text-uppercase fw-semibold small"
          >
            Total Jobs
          </div>
        </template>
      </CWidgetStatsF>
    </CCol>
  </CRow>
</template>

<script>
import { TruckSummary } from "@/lib/rule_lib/truck_summary";
import CWidgetStatsF from "@/components/widgets/CWidgetStatsF"

export default {
  name: "SummaryReportScopeTruck",
  components: {
    CWidgetStatsF
  },
  props: {
    summary: TruckSummary
  },
  data() {
    return {};
  },
  computed: {
    deviceId() {
      return this.summary.deviceId;
    },

    truckId() {
      return this.summary.truckId;
    },

    activeDates() {
      return this.summary.activeDates;
    },

    jobCounts() {
      return this.summary.jobCounts;
    },

    startDateString() {
      return new Date(this.summary.startTimestamp).toDateString();
    },

    endDateString() {
      return new Date(this.summary.endTimestamp).toDateString();
    },

    dateDiff() {
      return Math.round(
        (this.summary.endTimestamp - this.summary.startTimestamp) /
          (1000 * 60 * 60 * 24)
      );
    }
  },
  methods: {}
};
</script>

<style scoped>
.widget-value {
  font-size: 24px;
  text-align: center; /** Added this CSS item, suggest center */
  margin-left: 0px;  /** KH: Adjusted the margin to center text */
  color: var(--info);
  font-weight: 500;
}
</style>
