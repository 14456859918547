var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "summary" } },
    [
      _c(
        "CCard",
        { staticClass: "h-100" },
        [
          _c("CCardBody", { staticClass: "pt-1 pb-0 mb-0 card-header  " }, [
            _c("div", { attrs: { id: "truck-id" } }, [
              _vm._v(" TRUCK " + _vm._s(_vm.truckId) + " ")
            ]),
            _c("div", { attrs: { id: "range-from-to" } }, [
              _vm._v(" From "),
              _c("span", { staticClass: "date" }, [
                _vm._v(" " + _vm._s(_vm.startDateString) + " ")
              ]),
              _vm._v(" to "),
              _c("span", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm.endDateString) + " ")
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }