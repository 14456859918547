<template>
  <CDataTable
    :items="tableItems"
    :fields="fields"
    :items-per-page="20"
    hover
    pagination
    :cleaner="false"
    striped
    clickable-rows      
  >
    <template #id="{ item }">
      <ReportPerDayScopeTruckCard 
      
        :summary="item"
        @changeToDayView="changeToDayView"
        @changeToPrintableDayView="changeToPrintableDayView"
      />
    </template>
  </CDataTable>
</template>

<script>

import ReportPerDayScopeTruckCard from '@/components/reports/ReportPerDayScopeTruckCard';

export default {
  name: "ReportTableScopeTruck",
  components:{
    ReportPerDayScopeTruckCard,
  },
  props: {
    summaries: Object, // Object: {key: bin, value: TruckSummary}
  },
  data() {
    return {
      fields: [
        {key: 'id', label: ''},
      ],
    };
  },
  computed: {
    tableItems() { // Array
      return Object.values(this.summaries);
    },

    searchPlaceHolder () {
      return "Search For a Day"
    }
  },
  methods: {
    changeToDayView(timestamp) {
      this.$emit("changeToDayView", timestamp);
    },

    changeToPrintableDayView(timestamp) {
      this.$emit("changeToPrintableDayView", timestamp);
    }
  },
};
</script>